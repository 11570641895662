<template>
  
  <div>
    <div v-if="apps.length>0">
    <el-row :gutter="20" class="el-row" type="flex">
      <el-col
        :span="6"
        v-for="(item, index) in apps"
        :key="item.id"
        class="el-col"
      >
        <el-card class="el-cardm" :key="index" onclick="">
          <template v-slot:header class="clearfix">
            <span><font size="3">{{ item.appname }}</font></span>
          </template>
          <div>
            <div class="text item">
              <div>
                <span>题目数量&nbsp;<font color="blue" size="3">{{ item.question_num }}</font></span>
                <span style="margin-left:10px;">总分&nbsp;<font color="blue" size="3">{{ item.marks }}</font></span> 
                <span style="margin-left:10px;">时长&nbsp;<font color="blue" size="3">{{ item.time_length }}</font>&nbsp;分钟</span>
              </div>
              <div style="margin-top:5px;">
                <span>试卷年份：{{ item.dt.split(' ')[0] }}</span>
                <span style="margin-left:10px;">合格分&nbsp;<font color="blue" size="3">{{ item.qualified_score }}</font></span>
              </div>
              <div style="margin-top:5px;">
                <span><font color="gray" size="2">已做{{ item.myDo }}次</font></span> 
                <span style="margin-left:10px;"><font color="gray" size="2">考试时间:{{ item.doDt }}</font></span> 
                <img v-if="item.isPass==1" style="float:right;" src="../assets/Pass.png">
                <img v-else-if="item.isPass==0" style="float:right;" src="../assets/NotPass.png">
              </div>
            </div>
            
            
            
            <div >
              <el-button type="primary" @click="doTest(item.testId,item.id)">查看</el-button>
              
            </div>
            
          </div>
        </el-card>
      </el-col>
      
    </el-row>
    </div>
  <div v-else>
    <el-empty description="暂时没有已做试卷"></el-empty>
  </div>
  </div>
  
</template>

<script>

import {QueryMyDoTestPaper,FormationMyTest} from '../api/data.js';
import QRCode from 'qrcode'     //引入生成二维码组件
import global from '@/global';

export default {
  name: 'father',
  data() {
    return {
      apps: [],
      showModal: false,
      QueryDetail:'测试二维码',
      count: '',
      timeOut: null,
    };
  },
  mounted(){
      this.refreshData();
  },
  methods: {
    
    doTest(id,testId){
      //测试
      this.$router.push({
                path:'/TestPaperResult',
                name:'TestPaperResult',
                params:{
                  id:id,
                  testId:testId
                }
              });
    },
    refreshData(){
      this.apps = []; //先清空数组
      //向后台查询试卷清单
      let obj = {
        userId:global.machineId
      }
      let that = this; //很重要
      QueryMyDoTestPaper(obj).then(function(res){
        for(var i=0;i<res.data.length;i++)
        {
          var obj = new Object();
          obj.id = res.data[i].pkid;
          obj.testId = res.data[i].test_pkid;
          obj.appname = res.data[i].test_name;
          obj.marks = res.data[i].marks;
          obj.qualified_score = res.data[i].qualified_score;
          obj.question_num = res.data[i].question_num;
          obj.time_length = res.data[i].time_length;
          obj.dt = res.data[i].dt;
          obj.doDt = res.data[i].test_start_tm
          obj.myDo = res.data[i].myDO;
          obj.myScore = res.data[i].my_score;
          obj.isPass = (res.data[i].my_score/10)>=res.data[i].qualified_score?1:0;
          obj.showType = res.data[i].show_type;
          that.apps.push(obj);
        }
      });
      
    },
    
  },
  components: {
   
  },
};
</script>

<style type="text/css">
.all {
  margin-top: -30px;
  word-break: break-all;
  height: 100%;
}
.mid {
  margin-top: 25%;
  height: 50%;
}
.mid_item {
  justify-content: center;
  align-items: center;
}
.item {
  margin-bottom: 5px;
}

.text {
  width: 100%;
  font-size: 14px;
  color: #667083;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.el-cardm {
  min-width: 100%;
  height: 100%;
  margin-left:10px;
  margin-right: 10px;
  margin-top:10px;
  /*transition: all .5s;*/
}
.el-cardm:hover {
  margin-top: -5px;
}
.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.el-col {
  border-radius: 4px;
  align-items: stretch;
  margin-bottom: 20px;
}

.mask {
  background-color: rgb(173, 154, 154);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
    .pop {
  background-color: #fff;
  position: fixed;
  top: 200px;
  left: 500px;
  width: 400px;
  height:400px;
  z-index: 2
}

.licls {
  list-style: none;
  display: inline-block;
  margin: 5px 5px 10px 10px;
  color:rgb(60, 125, 245);
}

.licls a:hover {
  background-color: rgb(40, 218, 85);
  border-radius: 1px;
  cursor: pointer;
}

.payDiv{
text-align:center;
vertical-align: middle;
line-height: 32px;
}

.payDiv img{
  width: 20px;
  height: 20px;
}

.payDiv img:hover{
  background-color: rgb(163, 165, 163);
  border-radius: 1px;
  cursor: pointer;
}

#canvas{
  width:400;
  height:400;
}
</style>
